import { graphql } from 'gatsby';
import Link from 'gatsby-link';
import React from 'react';
import styled from 'styled-components/macro';

import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import HeaderSeo from '../components/HeaderSeo';
import Button from '@material-ui/core/Button';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import Footer from '../components/Footer';
import Header from '../components/Header';
import SocialMediaShareButtons from '../components/SocialMediaShareButtons';
import { StructuredText } from 'react-datocms';
import colors from '../assets/colors';
import fontSize from '../assets/fontSize';

const Container = styled.div`
  margin: auto;
  max-width: 1155px;
  padding-top: 7rem;
  padding-bottom: 70px;
  @media (max-width: 1200px) {
    margin-left: 1rem;
    margin-right: 1rem;
  }
`;
const StyledButton = styled(Button)`
  && {
    color: ${colors.primary};
  }
`;
const StyledLink = styled(Link)`
  text-decoration: none;
  @media (max-width: 750px) {
    padding-left: 0;
  }
`;
const BannerWrapper = styled.div`
  max-width: 570px;
  height: 323px;
  margin-bottom: 3rem;
  margin-top: 2rem;
  @media (max-width: 750px) {
    height: unset;
    margin-bottom: 2rem;
  }
`;
const Info = styled.p`
  font-size: ${fontSize.xLarge};
  font-weight: 600;
  margin-bottom: ${({ isExtraMarginRequired }) =>
    isExtraMarginRequired && '3rem'};
  @media (max-width: 750px) {
    margin-bottom: ${({ isExtraMarginRequired }) =>
      isExtraMarginRequired && '2rem'};
  }
`;
const GatsbyImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 2rem 0;
`;
const StyledStructuredText = styled.div`
  padding: 0 1rem;

  h1,
  h2 {
    margin: 2rem 0;
  }

  h3,
  h4,
  h5,
  h6 {
    margin: 1.25rem 0;
  }

  a {
    color: ${colors.primary};
    font-weight: 600;
    text-decoration: none;
  }
`;

const generateSchemaMarkup = blogPost => {
  const bannerImage =
    blogPost?.topbanner?.gatsbyImageData?.images?.fallback?.src;
  return JSON.stringify({
    '@context': 'https://schema.org',
    '@type': 'BlogPosting',
    'headline': blogPost?.title,
    'image': [bannerImage],
    'datePublished': blogPost?.meta?.publishedAt,
    'dateModified': blogPost?.meta?.updatedAt,
    'author': [
      {
        '@type': 'Organization',
        'name': 'GoGet Malaysia',
        'url': 'https://goget.my'
      }
    ]
  });
};

const BlogPage = props => {
  const { data } = props;
  const { datoCmsBlog } = data;
  const banner = datoCmsBlog?.topbanner?.gatsbyImageData?.images?.fallback?.src;

  return (
    <React.Fragment>
      <HeaderSeo
        isImageFromDatoCMS
        title={datoCmsBlog?.title}
        description={datoCmsBlog?.shortDescription}
        image={banner}
        keywords={datoCmsBlog?.seoKeywords}
        pathname={datoCmsBlog?.slug}
        schemaMarkup={generateSchemaMarkup(datoCmsBlog)}
      />
      <Header isHeaderShowingByDefault />
      <Container>
        <StyledLink to="/blog">
          <StyledButton startIcon={<ArrowBackIcon />}>GOGET BLOGS</StyledButton>
        </StyledLink>
        <h1>{datoCmsBlog?.title}</h1>
        <Info isExtraMarginRequired={!banner}>
          {datoCmsBlog?.category} {datoCmsBlog?.category && '/'}{' '}
          {datoCmsBlog?.date}
        </Info>
        {banner && (
          <BannerWrapper>
            <GatsbyImage
              image={getImage(datoCmsBlog?.topbanner)}
              style={{ borderRadius: '20px' }}
              alt={datoCmsBlog?.title}
            />
          </BannerWrapper>
        )}
        <StyledStructuredText>
          <StructuredText
            data={datoCmsBlog?.body}
            renderBlock={({ record }) => {
              if (record.__typename === 'DatoCmsInlineImage') {
                return (
                  <GatsbyImageWrapper>
                    <GatsbyImage
                      image={record?.inlineImage?.gatsbyImageData}
                      alt={datoCmsBlog?.title}
                    />
                  </GatsbyImageWrapper>
                );
              }
            }}
          />
        </StyledStructuredText>
        <SocialMediaShareButtons />
      </Container>
      <Footer />
    </React.Fragment>
  );
};

export const query = graphql`
  query PageQuery($pageIdJim: String) {
    datoCmsBlog(originalId: { eq: $pageIdJim }) {
      originalId
      title
      slug
      seoKeywords
      body {
        value
        blocks {
          __typename
          ... on DatoCmsInlineImage {
            id: originalId
            inlineImage {
              gatsbyImageData
            }
          }
        }
      }
      date(formatString: "DD MMM yyyy")
      category
      shortDescription
      topbanner {
        gatsbyImageData(width: 600, placeholder: BLURRED, forceBlurhash: false)
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      meta {
        publishedAt
        updatedAt
      }
    }
  }
`;

export default BlogPage;
