import React from 'react';
import styled from 'styled-components/macro';

import {
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookIcon,
  LinkedinIcon,
  TelegramIcon,
  TwitterIcon,
  WhatsappIcon
} from 'react-share';

import colors from '../assets/colors';
import fontSize from '../assets/fontSize';

const SocialMediaContainer = styled.div`
  background: ${colors.appleGreen};
  color: ${colors.black};
  max-width: 404px;
  border-radius: 10px;
  padding: 29px 28px;
  display: flex;
  align-items: center;
  margin-top: 4rem;
  @media (max-width: 500px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;
const Text = styled.p`
  font-weight: 700;
  font-size: ${fontSize.large};
  margin: 0 2rem 0 0;
  @media (max-width: 500px) {
    margin: 0 0 1rem 0;
  }
`;
const SocialButtonsWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-around;
  align-items: center;
`;

const SocialMediaShareButtons = () => {
  const shareUrl = typeof window !== `undefined` && window.location?.href;
  return (
    <SocialMediaContainer>
      <Text>Share this article</Text>
      <SocialButtonsWrapper>
        <FacebookShareButton url={shareUrl} style={{ marginRight: 16 }}>
          <FacebookIcon round bgStyle={{ fill: colors.black }} size="32" />
        </FacebookShareButton>
        <LinkedinShareButton url={shareUrl} style={{ marginRight: 16 }}>
          <LinkedinIcon round bgStyle={{ fill: colors.black }} size="32" />
        </LinkedinShareButton>
        <TwitterShareButton url={shareUrl} style={{ marginRight: 16 }}>
          <TwitterIcon round bgStyle={{ fill: colors.black }} size="32" />
        </TwitterShareButton>
        <WhatsappShareButton url={shareUrl} style={{ marginRight: 16 }}>
          <WhatsappIcon round bgStyle={{ fill: colors.black }} size="32" />
        </WhatsappShareButton>
        <TelegramShareButton url={shareUrl} style={{ marginRight: 16 }}>
          <TelegramIcon round bgStyle={{ fill: colors.black }} size="32" />
        </TelegramShareButton>
      </SocialButtonsWrapper>
    </SocialMediaContainer>
  );
};
export default SocialMediaShareButtons;
